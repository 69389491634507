<template>
    <ScrollableContainer>
        <div class="register-artist text-primary bg-light">
            <div class="register-artist__content">
                <header class="register-artist__header">
                    <img src="@/assets/logo_primary.svg" />
                    <button
                        class="button button-close"
                        @click="$router.replace('/dashboard')"
                    ></button>
                </header>

                <h2>Your artist page</h2>

                <form class="gb-form" autocomplete="nope" @submit.prevent="handleSubmit">
                    <fieldset class="gb-form__fieldset gb-form__fieldset--centered">
                        <div class="register-artist__profile-photo">
                            <ImageUploader v-model="profileImageFile" profile>
                                <template #temp>
                                    <div class="register-artist__profile-photo-placeholder">
                                        <Badge label="Profile photo" color="beige" />
                                        <img src="@/assets/icons/photo_camera_icon.svg" />
                                    </div>
                                </template>
                            </ImageUploader>
                        </div>
                    </fieldset>

                    <fieldset class="gb-form__fieldset">
                        <div class="register-artist__cover-photo">
                            <ImageUploader v-model="coverImageFile">
                                <template #temp>
                                    <div class="register-artist__profile-photo-placeholder">
                                        <Badge label="Cover photo" color="beige" />
                                        <img src="@/assets/icons/photo_camera_icon.svg" />
                                    </div>
                                </template>
                            </ImageUploader>
                        </div>
                    </fieldset>

                    <fieldset class="gb-form__fieldset">
                        <label for="name">Name</label>
                        <BaseInput
                            id="name"
                            v-model="currentState.name"
                            type="text"
                            placeholder="Artist name"
                            text-align="center"
                            required
                        />
                    </fieldset>

                    <fieldset class="gb-form__fieldset">
                        <label for="biography">Biography</label>
                        <Textarea
                            id="biography"
                            v-model="currentState.description"
                            placeholder="Write something about you"
                        ></Textarea>
                    </fieldset>
                    <fieldset class="gb-form__fieldset gb-form__fieldset--centered">
                        <p>Type of Artist</p>

                        <div
                            v-for="(types, category, index) in categoryPayload"
                            :key="category + index"
                        >
                            <h2 class="capitalize">{{ category }}</h2>
                            <div v-for="(ids, type, index2) in types" :key="type + index2">
                                <h4 class="capitalize">
                                    {{ type }}
                                </h4>
                                <p v-for="id in ids" :key="id" class="capitalize">
                                    {{ id.toLowerCase() }}
                                </p>
                            </div>
                        </div>
                        <Button
                            label="Add category"
                            size="small"
                            square
                            type="button"
                            @click="showCategoryPickerModal = true"
                        />
                        <Modal v-if="showCategoryPickerModal">
                            <CategoryPicker
                                v-model="currentState.categories"
                                @cancel="showCategoryPickerModal = false"
                            />
                        </Modal>
                    </fieldset>

                    <fieldset class="gb-form__fieldset gb-form__fieldset--col-2">
                        <legend class="gb-form--span-row">Average price range</legend>
                        <BaseInput
                            v-model.number="currentState.priceMin"
                            placeholder="from"
                            type="number"
                            text-align="left"
                        />
                        <BaseInput
                            v-model.number="currentState.priceMax"
                            placeholder="to"
                            type="number"
                            text-align="left"
                        />
                    </fieldset>

                    <fieldset class="gb-form__fieldset gb-form__fieldset--col-2">
                        <legend class="gb-form--span-row">Average price range (weekends)</legend>

                        <BaseInput
                            v-model.number="currentState.priceMinWeekend"
                            placeholder="from"
                            type="number"
                            text-align="left"
                        />
                        <BaseInput
                            v-model.number="currentState.priceMaxWeekend"
                            placeholder="to"
                            type="number"
                            text-align="left"
                        />
                    </fieldset>

                    <fieldset class="gb-form__fieldset">
                        <label for="location">Location</label>
                        <CitySearch
                            id="location"
                            placeholder="Primary work location"
                            required
                            @input="citySearchData = $event"
                        />
                    </fieldset>

                    <fieldset class="gb-form__fieldset">
                        <label for="address">Address</label>
                        <AddressLocationSearch
                            id="address"
                            placeholder="address"
                            @input="addressSearchData = $event"
                        />
                    </fieldset>

                    <fieldset class="gb-form__fieldset">
                        <legend>Contact information</legend>
                        <BaseInput
                            id="email"
                            v-model="currentState.email"
                            type="email"
                            placeholder="E-mail"
                            text-align="center"
                        />
                        <BaseInput
                            id="phone"
                            v-model="currentState.phone"
                            placeholder="Phone number"
                            text-align="center"
                        />
                        <BaseInput
                            id="web"
                            v-model="currentState.web"
                            type="url"
                            placeholder="http://yourwebsite.com"
                            text-align="center"
                        />
                    </fieldset>
                    <!-- <fieldset class="gb-form__fieldset">
                        <label>Social media</label>
                        <BaseInput
                            v-for="i in socialMediaInputs"
                            :id="'social-media' + i"
                            :key="i"
                            placeholder="http://socialmedia.com"
                        />
                        <Button
                            label="Add more"
                            square
                            size="small"
                            @click="socialMediaInputs > 5 ? socialMediaInputs : socialMediaInputs++"
                        />
                    </fieldset> -->
                    <JumboButton
                        v-show="changed"
                        label="Submit"
                        type="submit"
                        :style="{ marginBottom: '-1px' }"
                    />
                </form>
            </div>
        </div>
    </ScrollableContainer>
</template>

<script>
import Textarea from '@/components/Textarea'
import CategoryPicker from '@/components/CategoryPicker'
import Modal from '@/components/modals/Modal'
import Badge from '@/components/Badge'
import ImageUploader from '@/components/ImageUploader'
import AddressLocationSearch from '@/components/auto-suggest/AddressLocationSearch'
import CitySearch from '@/components/auto-suggest/CitySearch'
import JumboButton from '@/components/JumboButton'
import { CREATE_ARTIST_PROFILE } from '@/graphql/mutations/user'
import { ME, MY_ARTIST_PROFILE } from '@/graphql/queries/user'
import { toDto } from '@/mappers/category'
import RegisterProfileMixin from './RegisterProfileMixin'

export default {
    name: 'RegisterArtist',
    components: {
        Textarea,
        Badge,
        CategoryPicker,
        Modal,
        AddressLocationSearch,
        JumboButton,
        CitySearch,
        ImageUploader
    },
    mixins: [RegisterProfileMixin],
    inject: ['switchToArtistProfile'],
    data() {
        return {
            initialState: {},
            currentState: {},
            citySearchData: null,
            addressSearchData: null,
            // MODALS
            showCategoryPickerModal: false,

            // IMAGES
            profileImageFile: null,
            coverImageFile: null,

            // OTHER!
            socialMediaInputs: 1
        }
    },
    computed: {
        changed() {
            return (
                JSON.stringify(this.currentState) !== JSON.stringify(this.initialState) ||
                this.profileImageFile ||
                this.coverImageFile
            )
        },

        categoryPayload() {
            return toDto(this.currentState.categories || [])
        },
        payload() {
            const { email, phone, web } = this.currentState

            const location = this.citySearchData
                ? {
                      lat: this.citySearchData.lat,
                      long: this.citySearchData.long
                  }
                : null
            const address = this.addressSearchData
                ? {
                      street: this.addressSearchData.name,
                      zip: this.addressSearchData.zip,
                      city: this.addressSearchData.postal
                  }
                : null

            const contact =
                email || phone || web
                    ? {
                          email: this.currentState.email,
                          phone: this.currentState.phone,
                          web: this.currentState.web
                      }
                    : null

            const {
                priceMin,
                priceMax,
                priceMinWeekend,
                priceMaxWeekend,
                description,
                categories
            } = this.currentState
            const catDto = toDto(categories)
            return {
                name: this.currentState.name,
                description: description ? description : undefined,
                priceMin: priceMin ? priceMin : undefined,
                priceMax: priceMax ? priceMax : undefined,
                priceMinWeekend: priceMinWeekend ? priceMinWeekend : undefined,
                priceMaxWeekend: priceMaxWeekend ? priceMaxWeekend : undefined,
                categories: Object.keys(catDto).length ? catDto : undefined,
                location: location ? location : undefined,
                address: address ? address : undefined,
                contact: contact ? contact : undefined
            }
        }
    },
    mounted() {
        this.initialState = {
            name: '',
            description: '',
            priceMin: '',
            priceMax: '',
            priceMinWeekend: '',
            priceMaxWeekend: '',
            email: '',
            phone: '',
            web: '',
            location: null,
            worklocation: null,
            street: '',
            zip: '',
            city: '',
            categories: []
        }

        this.currentState = JSON.parse(JSON.stringify(this.initialState))
    },

    methods: {
        // TODO: validate file size
        // TODO: ADD MORE SOCIAL MEDIA
        async handleSubmit() {
            console.log('submitting')
            try {
                // Start registration
                console.log('Starting createArtistProfile PROCESS')

                console.log('creating new profile...')
                const result = await this.$apollo.mutate({
                    mutation: CREATE_ARTIST_PROFILE,
                    variables: {
                        input: {
                            data: this.payload
                        }
                    },
                    refetchQueries: [{ query: ME }, { query: MY_ARTIST_PROFILE }]
                })
                const { type, errors, artist } = result.data.createArtistProfile

                if (type === 'ResponseError') {
                    errors.forEach((error) => console.log(error))
                    return
                }
                if (type === 'createArtistPayload') {
                    console.log('Created new artist profile')
                    // Fetch my artist profile manually
                    await this.$apollo.query({
                        query: MY_ARTIST_PROFILE,
                        fetchPolicy: 'network-only'
                    })
                    this.switchToArtistProfile()

                    // Upload images if any
                    this.$_uploadImages(artist.media.id, [
                        { type: 'profile', file: this.profileImageFile },
                        { type: 'cover', file: this.coverImageFile }
                    ])
                    console.log('Success!')
                    this.$router.replace('/dashboard')
                }
            } catch (error) {
                console.log({ error })
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.scrollable-container {
    height: 100%;
    overflow: auto;
}
.register-artist {
    padding-bottom: 150px;
    text-align: center;
    &__header {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr auto 1fr;
        height: 90px;
        padding: 1rem;
        :nth-child(1) {
            grid-column-start: 2;
            width: 60px;
        }
        :last-child {
            justify-self: end;
        }
    }

    &__profile-photo {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #fff;
        row-gap: 20px;
        overflow: hidden;
    }

    &__cover-photo {
        width: 100%;
        height: 160px;
        background: #fff;
    }

    &__profile-photo-placeholder {
        display: grid;
        place-items: center;
        place-content: center;
        row-gap: 1rem;
        height: 100%;
        width: 100%;
        label {
            display: block;
        }
    }
}
@media only screen and (min-width: 768px) {
    /* For desktop: */
    .register-artist {
        &__content {
            max-width: 768px;
            margin: auto;
        }
    }
}
</style>
